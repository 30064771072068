import React from 'react';
import {Routes, Route} from "react-router-dom";
import Navbar from './Component/Navbar';
import Homepage from './Component/Homepage';
import AboutUs from './Component/Pages/AboutUs';
import Privacy from './Component/Pages/Privacy';
import ContactUs from './Component/Pages/ContactUs';
import Tc from './Component/Pages/Tc';
import Footer from './Component/footer/Footer';
import Scroll from './Component/footer/Scroll';
import HttpsRedirect from 'react-https-redirect';

function App() {
  return (
    <>
    <HttpsRedirect>
      <Navbar/>
        <Routes>
          <Route exact path='*' Component={Homepage} />
          <Route exact path='/contact' Component={ContactUs} />
          <Route exact path='/about' Component={AboutUs} />
          <Route exact path='/privacy_policy' Component={Privacy} />
          <Route exact path='/term_condition' Component={Tc} />
        </Routes>
      <Scroll />  
      <Footer/>
    </HttpsRedirect>
    </>
  );
};

export default App;
