import React from 'react'
import { useState, useEffect } from 'react';
import {FaAngleUp} from 'react-icons/fa';

const Scroll = () => {
    const [showBtn, setShowBtn] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 100) {
                setShowBtn(true);
            } else {
                setShowBtn(false);
            }
        });
    }, []);


    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <div className="topBtn">
            {" "}
            {showBtn && (
                <FaAngleUp
                    className="iPos iStyle"
                    onClick={goToTop}
                />
            )}{" "}
        </div>
    );
};
export default Scroll;