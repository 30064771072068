import React from 'react'

export default function Privacy() {
  return (
  
  <>
    <div className='container-fluid contTerm'>
      <div className='content'>
        
        <h5 className='fw-bold'>Privacy Policy</h5>
        <p>The information we learn from customers helps us personalize and continually improve your shopping experience
            at Rework. We use your information to assist in handling a variety of matters including but not related to ,
            communicate with you about vouchers, products, services and promotional offers, update our records and
            generally maintain your accounts with us, display content and recommend merchandise and services that might
            be of interest to you. We also use this information to improve our platform, prevent or detect fraud or
            abuses of our website and enable you to have a grand experience. We use Cookies and Device Data that allow
            us to connect your activity within the Platform with other information we store about you in your profile or
            as related to your interactions with the Site. We collect information to provide better services to all our
            users from figuring out basic stuff such as which language you speak, to more complex things like which
            services you find helpful or which services are available in your current location. The information Rework
            collects, and how that information is used, depends on how frequently you use our services and how you
            manage your privacy controls internally within your device. We use the information we collect to customise
            our services for you, including providing recommendations and personalized content. We will ask for your
            explicit consent to share any sensitive personal information. Additionally, we will also ask for your
            consent before using your information for a purpose that isn’t covered in this Privacy Policy.</p>
        <div>
            <ol>
                <li>
                    User Information<br></br>
                    <ol>
                        <li>The Application obtains the information you provide when you download and register the
                            Application.</li>
                        <li>When you register with us and use the Application, you generally provide (a) your name,
                            email address, phone number and other registration information; (b) transaction-related
                            information, such as when you make purchases and/or upload a selfie, respond to any offers,
                            or download or use any applications from us; (c) information you provide us when you contact
                            us for help; (d) any other information you enter into our system when using the Application,
                            such as contact information and project management information.</li>
                        <li>The Application may also collect certain information automatically, including, but not
                            limited to, the type of mobile device you use, your mobile devices unique device ID, circle
                            of the IP address of your mobile device, your mobile operating system, the type of mobile
                            Internet browsers you use, and information about the way you use the Application.</li>
                        <li>We may use the information you provided us to contact you from time to time to provide you
                            with important information, required notices and marketing promotions and also improve your
                            activities on our platform such as posting in our forums, liking posts, sending feedback,
                            and responding to surveys. If you choose to share data about yourself via your profile,
                            chat, or other interactive areas of the Application, please be advised that all data you
                            disclose in these areas is public and your data will be accessible to anyone who accesses
                            the Application. Rework shall not be liable for any data that you had chosen to disclose
                            yourself in this public space.</li>
                        <li>We may also use your personal information to improve our services and user experience on the
                            Application, to enforce our legal rights as permitted under law, and otherwise with your
                            consent.</li>
                        <li>You may restrict our access to such information by terminating your account on Rework or
                            removing Rework permission to access the same by directly disabling the same on your phone.
                            The User may note that the latter may severely restrict Rework ability to provide a
                            personalized feel to the Platform.</li>
                    </ol>
                </li>
                <br></br>
                <li>
                    Geo-Location Information<br></br>
                    <ol>
                        <li>We collect information about your location when you use our services, which helps us in
                            determining the merchants and outlets near you where Rework vouchers can be used or cashback
                            can be availed. Your location can be determined with varying degrees of accuracy by: (a)
                            GPS; (b) IP address; (c) Sensor data from your device; (d) Information about things near
                            your device, such as Wifi Access Points, cell towers and Bluetooth enabled devices.</li>
                        <li>We may request access or permission to and track location-based information from your mobile
                            device while you are using the Application, to provide location-based services. We may also
                            collect the precise location of your device when the app is running in the foreground,
                            background, or even if you are not directly using the application depending on your device
                            and its settings. Your device, as well as the Rework mobile app, provides you with options
                            to control how and when we collect your geolocation. This is only to better the User’s
                            experience on the Rework Platform.</li>
                        <li>You may be able to disallow our use of certain location data through your device or browser
                            settings, for example, by disabling Location services for the Rework application in your
                            device privacy settings. By allowing Rework access to the same the User acknowledges and
                            accepts that Rework shall be aware of the User’s Geo-Location at any time. If you wish to
                            change our access or permissions, you may do so in your device’s settings.</li>
                    </ol>
                </li>
                <br></br>
                <li>
                    Contacts Information<br></br>
                    <ol>
                        <li>Please note that no other contacts information other than the phone numbers and thereto
                            attached names and email addresses will be collected and used from Your address book. Other
                            numbers or information that may be contained in your address book will be filtered away by
                            our safety algorithms and will therefore not be collected by Rework . Please also note that
                            You can always choose not to share Contacts Information with Rework</li>
                        <li>Friends on Rework help you discover the coolest things in town. From best food outlets to
                            best picnic spots, your connects keep you up to date with latest trends.</li>
                        <li>When you turn on phone contacts uploading, information from your phone contacts will be
                            continuously uploaded. This makes it easier to find the people you already know.</li>
                    </ol>
                </li>
                <br></br>
                <li>
                    Financial Information<br></br>
                    <ol>
                        <li>Availing the services offered by Rework can at times involve the collection of sensitive
                            financial information including but not limited to linking with mobile wallets, entering
                            credit/debit card details, password, etc to facilitate payment for the respective service.
                            You acknowledge and accept that linking Rework with relevant mobile wallet or bank account
                            helps in making the experience better and thereby expressly consent to Rework collection and
                            subsequent processing of such financial data.</li>
                        <li>The financial information we learn and gather from you is used to link Rework with your
                            preferred mobile wallet, verify your identity to permit you to undertake transactions
                            (including to facilitate and process payments) in connection with Rework, communicate with
                            you about any promotional offers, services or updates associated with Rework , conduct
                            analysis on the users’ spending capability and generally maintain your accounts with us. No
                            information collated while carrying out the above is shared with or sold to a third party by
                            Rework and is strongly protected. You acknowledge and accept that the above activities that
                            involve Rework use of your financial information is to improve your experience on the Rework
                            platform and hereby provide Rework consent for the same.</li>
                        <li>We work to protect the security of your information during transmission by encrypting all
                            financial information, including passwords, you input in addition to maintaining security of
                            such information. User authentication is handled by Rework through authentication tokens
                            that is reset on every fresh one time password validation. We reveal only the last four
                            digits of your credit / debit card numbers while making payment. Of course, we transmit the
                            entire credit / debit card number to the appropriate credit / debit card company or the
                            concerned bank for processing of the transaction.</li>
                        <li>We maintain physical, electronic and procedural safeguards in connection with the
                            collection, storage and disclosure of such sensitive personal information.</li>
                        <li>You may disable Rework access to the same remotely on the Rework Platform or contact us at
                            <b>info@rework.com</b></li>
                    </ol>
                </li>
                <br></br>
                <li>
                    Health Information<br></br>
                    <ol>
                        <li>If while purchasing any healthcare product or services, you upload the invoice of the same
                            or use Rework voucher(s) or magic pay to purchase the same, Rework would gain access to
                            information on such invoice(s) which may include your sensitive personal health information.
                        </li>
                        <li>You hereby expressly consent to Rework gaining access and storing such information and
                            processing the same in the manner set out in these terms.</li>
                        <li>Rework shall have the rights in the above clause (b) unless you expressly withdraw the
                            consent you have given us, by contacting us with such request at support@Rework</li>
                    </ol>
                </li>
                <br></br>
                <li>
                    Third Party Access to User Information<br></br>
                    <ol>
                        <li>We will share your information with third parties only in the ways that are described in
                            this privacy statement.</li>
                        <li>We may disclose User Provided and Automatically Collected Information unless consent to the
                            same has been specifically withdrawn by the User.</li>
                        <li>We will not share your contact information at any point without your prior content, but your
                            selfies, comments and other actions specific to our system might be visible to other users
                            and merchants on the platform. However, without your prior consent such users will not have
                            access to your contact information. You, as a User, have the ability, to remove your
                            information from the system and to restrict our access and use of your personal data;</li>
                        <li>We shall provide all information that is to be provided when required by law, such as to
                            comply with a summons, or similar legal process as well as when we believe in good faith
                            that disclosure is necessary to protect our rights, protect your safety or the safety of
                            others, investigate fraud, or respond to a government request;</li>
                        <li>Our trusted services providers who work on our behalf, do not have an independent use of the
                            information we disclose to them, and have agreed to adhere to the rules set forth in this
                            privacy statement and apply data protection measures, at least equal to those set out below
                            in the section “Data Security Measures”.</li>
                        <li>If in the event that Rework is involved in a merger, acquisition, or sale of all or a
                            portion of its assets, you will be notified via email and/or a prominent notice on our Web
                            site of any change in ownership or uses of this information, as well as any choices you may
                            have regarding this information.</li>
                        <li>We also may share data that is not linkable to a particular consumer or device (for example,
                            information that has been aggregated with other records) for general business purposes.</li>
                    </ol>
                </li>
                <br></br>
                <li>
                    Data Security Measures<br></br>
                    <ol>
                        <li>Rework takes security of your data very seriously. We work hard to protect information you
                            provide from loss, misuse, and unauthorised access or disclosure.</li>
                        <li>We maintain commercially reasonable and standard measures to maintain information security
                            and prevent it from unauthorized access. However, given the nature of communications and
                            information processing technology, Rework cannot guarantee that any information, during
                            transmission through the internet or while being processed, will be absolutely safe from
                            intrusion by others.</li>
                        <li>If Rework becomes aware of any breach of security of your information, We will notify you
                            using the email address that We have. If you do not agree to the terms discussed above, you
                            may exit Rework or stop using the same. When you access Our App, you acknowledge that you
                            have read and agreed to abide by the terms described above.</li>
                    </ol>
                </li>
                <br></br>
                <li>
                    Data Retention Policy<br></br>
                    <ol>
                        <li>We will retain User Provided data for as long as you use the Application and for a
                            reasonable time thereafter.</li>
                        <li>We will retain Automatically Collected information for up to 24 months and thereafter may
                            store it in aggregate.</li>
                        <li>If you would like us to delete User Provided Data that you have provided via the
                            Application, please contact us at <strong>info@rework.com</strong> and we will respond in a
                            reasonable time.</li>
                        <li>The User must note that some or all the User Provided Data may be required in order for the
                            Application to function properly.</li>
                    </ol>
                </li>
                <br></br>
                <li>
                    Tracking Technologies<br></br>
                    <ol>
                        <li>We may use cookies, web beacons, tracking pixels, and other tracking technologies on the
                            Application to help customize the Application and improve your experience. When you access
                            the Application, your personal information is not collected using tracking technology. Most
                            browsers are set to accept cookies by default. You can remove or reject cookies but be aware
                            that such action could affect the availability and functionality of the Application.</li>
                        <li>The Application may contain links to third-party websites and applications of interest,
                            including advertisements and external services, that are not affiliated with us. Once you
                            have used these links to leave the Application, any information you provide to these third
                            parties is not covered by this Privacy Policy, and we cannot guarantee the safety and
                            privacy of your information. Before visiting and providing any information to any
                            third-party websites, you should inform yourself of the privacy policies and practices (if
                            any) of the third party responsible for that website, and should take those steps necessary
                            to, in your discretion, protect the privacy of your information. We are not responsible for
                            the content or privacy and security practices and policies of any third parties, including
                            other sites, services or applications that may be linked to or from the Application.</li>
                    </ol>
                </li>
                <br></br>
                <li>Release of User Information: We release account and other personal information when we believe
                    release is appropriate to comply with applicable law; enforce or apply our Conditions of Use and
                    other agreements; or protect the rights, property or safety of merchants, our users or others. This
                    includes exchanging information with other companies, organizations, government or regulatory
                    authorities for fraud protection and credit risk reduction. Obviously, however, this does not
                    include selling, renting, sharing or otherwise disclosing personally identifiable information from
                    customers for commercial purposes in a way that is contrary to the commitments made in this Privacy
                    Notice
                </li>
                <br></br>
                <li>
                    Disclosure of Information<br></br>
                    <ol>
                        <li>to report or collect on payments owed to Sellers, merchants or other business partners;</li>
                        <li>as necessary to perform contractual obligations towards you with merchant to the extent you
                            have purchased or redeemed a voucher for goods or services offered by a merchant or
                            participated in an offer, rewards, contest or other activity or program sponsored or offered
                            through us or the Sellers on behalf of a merchant;</li>
                        <li>in case of a merger, acquisition or reorganization with a purchaser of our company or all or
                            substantially all of our assets;</li>
                        <li>to comply with legal orders and government requests, or as needed to support auditing,
                            compliance;</li>
                        <li>to combat fraud or criminal activity, and to protect our rights or those of our affiliates,
                            business partners and users, or as part of legal proceedings affecting Rework;</li>
                        <li>in response to a legal process, including to law enforcement agencies, regulators, and
                            courts; or</li>
                        <li>with your consent or as otherwise required or permitted by law.</li>
                    </ol>
                </li>
                <br></br>
                <li>User Opt-out: The User can choose to stop all collection of information by the Application easily by
                    uninstalling the Application. You may use the standard uninstall processes as may be available as
                    part of your mobile device or via the mobile application marketplace or network. You can also
                    request to opt-out via email, at <b>info@rework.com.</b>
                </li>
                <br></br>
                <li>Rework shall not engage in any use of the Application to knowingly solicit data from or market to
                    children under the age of 13. While it is our constant endeavour to show minors only age-appropriate
                    content through various internal checks, such as age verification at the time of registration,
                    directly or through Facebook, Community Guidelines and others that are updated periodically, if a
                    parent or guardian becomes aware that his or her child has provided us with information without
                    their consent, he or she should contact us at<b> <b>info@rework.com</b></b> We will delete such
                    information from our files within a reasonable time.</li>
                    <br></br>
                <li>You may at any time review or change the information in your account or terminate your account. If
                    your Rework user account is terminated or deleted, access to your username, password, and all related
                    information, files, and content associated with your account shall be terminated. However, some
                    information may be retained in our files to prevent fraud, troubleshoot problems, assist with any
                    investigations, enforce our Terms of Use and/or comply with legal requirements.</li>
                    <br></br>
                <li>By using or continuing to use Rework, you agree to our use of your information (including your
                    personal information and/or your sensitive personal data or information) in accordance with this
                    Privacy Policy, as may be amended from time to time by Rework in its discretion. You also agree and
                    consent to us collecting, storing, processing, transferring and sharing information (including
                    sensitive personal data or information) related to you with third parties or service providers for
                    the purposes as set out in this Privacy Policy.</li>
                    <br></br>
                <li>You may contact us at <b>info@rework.com</b> if you wish to obtain: (a) confirmation on whether your
                    respective Personal Data has been processed or is being processed; (b) brief summary of your
                    respective Personal Data that has been processed or is being processed; and (c) brief summary of the
                    Terms. You may also contact us if you wish any respective information to be corrected or removed or
                    deleted.</li>
            </ol>
        </div>
      </div>
    </div>  
    <hr></hr>
  </>

  )
}
