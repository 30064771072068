import React from 'react'
import logo from '../Image/about.gif'
import {Link} from 'react-router-dom'

export default function AboutUs() {
  return (
  <>
    {/* Code For About Us Page*/}
    <div className="container homepage">
        <div className="row">
            <div className="col-sm-6">
              <div className="card d-flex border-0">
                <div className="card-body mt-4">
                    <p className='card-text my-0'>Welcome to</p>
                    <p className="card-title my-0">Rework Here</p>
                    <p className="card-text my-0">About Us page</p>
                    <p className="card-text text-muted my-0">Have a look About Rework and Our Mission <br/>Work on you own</p>
                    <p className="text my-3"><span className="text-primary">Comfort</span> & <span className="text-primary">Space</span></p>
                    <div className="my-2">
                      <Link className='btn btn-lg btn-primary mx-0' target="_blank" to='https://play.google.com/store/apps/details?id=com.techup.rework'><span>Download App</span></Link>
                      <Link className='btn btn-lg btn-success ms-3' to='/contact'><span>Get Quote</span></Link>
                    </div>
                    <hr className="my-5 w-auto"></hr>
                </div>
              </div>
            </div>
          
          <div className="col-sm-6">
            <img src={logo}  alt="logo" className="logo" />
          </div>
        </div>
    
    {/* Code For body having content*/}

        <h1 className="text-center text-primary mt-4">About Rework Here</h1>
        <div className="row my-5 text-center">

          <div className="col-sm-4 cardCol">
            <div className="Card h-100 border border-0 cardEffect">
              <img src={require('../Image/Team.jpg')} alt='img' className='card-img-top AboutImg'/>
              <div className='Card-body'>
                <h4 className='card-title fw-bold my-3'>Who we are</h4>
                <p className='card-text text-muted mx-2 mb-3 text-start'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                  sed do eiusmod tempor incididunt ut labore et dolore magna 
                  aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
                  ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                  Duis aute irure dolor in reprehenderit in voluptate velit 
                  esse cillum dolore eu fugiat nulla pariatur. Excepteur sint 
                  occaecat cupidatat non proident, sunt in culpa qui officia 
                  deserunt mollit anim id est laborum.
                </p>
              </div>
            </div>
          </div>

          <div className="col-sm-4 cardCol">
            <div className="Card h-100 border border-0 cardEffect">
              <img src={require('../Image/Join.jpg')} alt='img' className='card-img-top AboutImg'/>
              <div className='Card-body'>
                <h4 className='card-title fw-bold my-3'>Why Join Us</h4>
                <p className='card-text text-muted mx-2 mb-3 text-start'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                  sed do eiusmod tempor incididunt ut labore et dolore magna 
                  aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
                  ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                  Duis aute irure dolor in reprehenderit in voluptate velit 
                  esse cillum dolore eu fugiat nulla pariatur. Excepteur sint 
                  occaecat cupidatat non proident, sunt in culpa qui officia 
                  deserunt mollit anim id est laborum.
                </p>
              </div>
            </div>
          </div>

          <div className="col-sm-4 cardCol">
            <div className="Card h-100 border border-0 cardEffect">
              <img src={require('../Image/Mission.jpg')} alt='img' className='card-img-top AboutImg'/>
              <div className='Card-body'>
                <h4 className='card-title fw-bold my-3'>Our Mission</h4>
                <p className='card-text text-muted mx-2 mb-3 text-start'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                  sed do eiusmod tempor incididunt ut labore et dolore magna 
                  aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
                  ullamco laboris nisi ut aliquip ex ea commodo consequat. 
                  Duis aute irure dolor in reprehenderit in voluptate velit 
                  esse cillum dolore eu fugiat nulla pariatur. Excepteur sint 
                  occaecat cupidatat non proident, sunt in culpa qui officia 
                  deserunt mollit anim id est laborum.
                </p>
              </div>
            </div>
          </div>

        </div>
    </div>
    <hr></hr>
  </>
  )
}
