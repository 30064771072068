import React from 'react'
import ContactImg from '../Image/contact.gif'

export default function ContactUs() {
  return (
  <>
    <div className="container">
      <div className="row contact">

        <div className="col-sm-5">

          <h2 className="text-primary fw-bolder"> Get in touch!!</h2>
          <span className="text-muted fs-5 fw-normal">We are here for you, Please describe how can we help?</span>
          <form className='mt-4' action="/">
            <div className="mb-3 mt-3">
              <input type="name" className="form-control" height="80" id="name" placeholder="Enter your name" name="YourName" required/>
            </div>
            <div className="mb-3">
              <input type="email" className="form-control" id="email" placeholder="Enter your email" name="email" required/>
            </div>
            <div className="mb-3">
              <input type="Number" className="form-control" id="num" placeholder="Enter your mobile number" name="number" required/>
            </div>
            <textarea className="form-control" rows="5" id="comment" name="text" placeholder='Please let us know that how can we help you ?' required></textarea>
            <div className="form-check my-3">
              <label className="form-check-label">
                <input className="form-check-input" type="checkbox" name="remember"/> Remember me
              </label>
            </div>
            <div className='mt-2'>
              <button type='reset' className='btn btn-success me-2' data-bs-toggle='tooltip' title='Clear Response'>Clear</button>
              <button type="submit" className="btn btn-primary ms-2" data-bs-toggle='tooltip' title='Submit Response'>Submit</button>
            </div>
          </form>
        </div>
      
        <div className="col-sm-7">
          <img src={ContactImg}  alt="logo" className="ContImg"/>
        </div>

      </div>

      <div className='row mt-5'>

        <div className='col-md-4 detail'>
          <div className='card cardCont h-100'>
            <div className='card-body d-flex flex-column align-items-center'>
            <img src={require('../Image/phone.png')} alt='img' className='card-img-top ContCardImg'/>
                <span className='mt-4 fw-normal fs-5'><strong>Call us on</strong></span>
                <p className='mt-3 fw-normal text-primary'>+91 8090575859</p>
            </div>
          </div>
        </div>

        <div className='col-md-4 detail'>
          <div className='card cardCont h-100'>
            <div className='card-body d-flex flex-column align-items-center'>
            <img src={require('../Image/email.png')} alt='img' className='card-img-top ContCardImg'/>
                <span className='mt-4 fw-normal fs-5'><strong>Mail us on</strong></span>
                <p className='mt-3 fw-normal text-primary'>info@reworkhere.in</p>
            </div>
          </div>
        </div>

        <div className='col-md-4 detail'>
          <div className='card cardCont h-100'>
            <div className='card-body d-flex flex-column align-items-center'>
            <img src={require('../Image/location.png')} alt='img' className='card-img-top  ContCardImg'/>
                <span className='mt-4 fw-normal fs-5'><strong>Our office address</strong></span>
                <p className='mt-3 fw-normal text-primary text-center cardAdd'>No- 152 C 08, H, 03, Ayush Vihar, phase - 2, Jankipuram Extension, Lucknow, Uttar Pradesh 226031</p>
            </div>
          </div>
        </div>  

      </div>

    </div>
    <hr></hr>
  </>
  )
}

