import React from 'react'
import {Link} from 'react-router-dom'

export default function Navbar() {
  return (
  <>
    <div className='container-fluid'>
      <nav className="navbar navbar-expand-sm px-4 fixed-top">
          <Link className="navbar-brand" to="/">REWORK HERE</Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="collapsibleNavbar">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link mx-3" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-3" to="/about">About us</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-3" to="/contact">Contact us</Link>
              </li>
            </ul>
          </div>
      </nav>
    </div>
  </>
  )
}
