import React from 'react'
import logo from './Image/Work.gif'
import {Link} from 'react-router-dom'

export default function Homepage() {
  return (
  <>

    {/* Code For Landing Page*/}
    <div className="container">
        <div className="row">
            <div className="col-sm-6">
              <div className="card d-flex border-0">
                <div className="card-body mt-4">
                    <p className='card-text my-0'>Welcome to</p>
                    <p className="card-title my-0 ReworkAni">Rework Here</p>
                    <p className="card-text my-0"> Join us on your part time work</p>
                    <p className="card-text text-muted my-0">Work on you own</p>
                    <p className="text my-3"><span className="text-primary">Comfort</span> & <span className="text-primary">Space</span></p>
                    <div className="my-2">
                        <Link className='btn btn-lg btn-primary mx-0' target="_blank" to='https://play.google.com/store/apps/details?id=com.techup.rework'><span>Download App</span></Link>
                        <Link className='btn btn-lg btn-success ms-3' to='/contact'><span>Get Quote</span></Link>
                    </div>
                    <hr className="my-5 w-auto"></hr>
                </div>
              </div>
            </div>
          
          <div className="col-sm-6">
            <img src={logo}  alt="logo" className="logo" />
          </div>
        </div>
    
    
        {/* Code For body having content*/}

    
        <h1 className="text-center text-primary mt-5">Steps to start work & earn</h1>
        <div className="row mt-5 mb-2">
            <div className="col-md-6">
                <div className="card border-0">
                    <div className='card-header border-0 h4 '>Register Yourself</div>
                    <div className="card-body d-flex flex-row">
                    <img src={require('./Image/enrollment.png')} 
                    alt="login" className="login me-2" />
                        <p className="text-start text-muted ms-2 ">
                            To get start with Rework Here you have to first Create your account or 
                            login if you have an account on Rework Here.
                            This can be done by Rework Here app or You can continue on the web. 
                            This process is smooth and hassle free.
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-md-6"></div>
        </div>

        <div className="row my-2">
            <div className="col"></div>
            <div className="col-md-6">
                <div className="card border-0">
                    <div className='card-header border-0 h4 '>Get Training</div>
                    <div className="card-body d-flex flex-row">
                    <img src={require('./Image/presentation.png')} 
                    alt="login" className="login me-2" />
                        <p className="text-start text-muted ms-2 ">
                            After Successful Registration,
                            our expert will help you to enhance your skills so
                            that you can execute your work fluently. Our training modules are 
                            designed to equip you with 
                            the knowledge and tools necessary to excel in your tasks. 
                            For that contact us to our customer support services.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div className="row my-2">
            <div className="col-md-6">
                <div className="card border-0">
                    <div className='card-header border-0 h4 '>Select your task</div>
                    <div className="card-body d-flex flex-row">
                    <img src={require('./Image/Working.png')} 
                    alt="login" className="login me-2" />
                        <p className="text-start text-muted ms-2 ">
                            After getting training and Enhancing your skills to be an expert in
                            your chosen field you have to choose the task in for what you 
                            want to work. You can skip the above step if you are qualified in the 
                            selected task and start working.
                        </p>
                    </div>
                </div>
            </div>
            <div className="col"></div>
        </div>

        <div className="row my-2">
            <div className="col"></div>
            <div className="col-md-6">
                <div className="card border-0">
                    <div className='card-header border-0 h4 '>Start Earning</div>
                    <div className="card-body d-flex flex-row">
                    <img src={require('./Image/salary.png')} 
                    alt="login" className="login me-2" />
                        <p className="text-start text-muted ms-2 ">
                        After selecting your desired task you have to complete that task with in the given working 
                        hour. We respect your hard work and dedication. Once our team approve work completed by you we will release your 
                        payment within 24 working hour. 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr></hr>
  </>
  )
}
