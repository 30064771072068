import React from 'react'
import {Link} from 'react-router-dom'

export default function Footer() {
  return (

    <div className="container footer">
        <div className='row'>
            <div className='col-md-4'>
                <p className="text-center">&copy; All Rights Reserved - Reworkhere.in | Developed by <Link to="https://www.techuptechnologies.com/" target="_blank"><strong>Techup Technologies</strong></Link></p>
            </div>

            <div className='col-md-4'>
                <ul className='list-TC d-flex'>
                    <li className='me-2'><a href='/term_condition'>Terms & Condition</a></li>
                    <p>|</p>
                    <li className='ms-2'><a href='/privacy_policy'>Privacy policy</a></li>
                </ul>
            </div>

            <div className='col-md-4'>
                <ul className="listIcon d-flex">
                    
                    <li className='me-2'>
                        <Link to='/'>
                            <img src={require('./facebook.png')} 
                            alt="sm-icon" className="sm-icon me-2" />
                        </Link>
                    </li>

                    <li className='me-2'>
                        <Link to='/'>
                            <img src={require('./instagram.png')} 
                            alt="sm-icon" className="sm-icon me-2" />
                        </Link>
                    </li>

                    <li className='me-2'>
                        <Link to='/'>
                            <img src={require('./twitter.png')} 
                            alt="sm-icon" className="sm-icon me-2" />
                        </Link>
                    </li>

                    <li>
                        <Link to='/'>
                            <img src={require('./linkedin.png')} 
                            alt="sm-icon" className="sm-icon me-2" />
                        </Link>
                    </li>

                </ul>
            </div>

        </div>
    </div>
    
  )
}
