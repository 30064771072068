import React from 'react'

export default function Tc() {
  return (

  <>  
    <div className='container-fluid contTerm'>
      <div className='content'>
        <h5 className='fw-bold'>Term &amp; Condition</h5>
        <p><strong>Rework</strong>, a company incorporated under the provisions of the Companies Act, 2013 with its
            registered office at Varanasi, UP, India 221010. These Terms of Use govern you, the User of Rework and your
            conduct, regardless of the means of access. You represent and warrant you possess the capacity and legal
            right to enter into this Agreement and to use this Website in accordance with the terms and conditions
            herein. You also acknowledge and agree that, unless we specifically provide otherwise, this Agreement only
            applies to this site and our online activities and does not apply to any of our offline activities. By using
            the various services available on the Rework Platform (Services), you consent to these terms, Rework Privacy
            Policy, and any community guidelines and supplemental terms provided to you for the Services that you use
            (collectively, Terms) and Rework efforts to improve every User’s experience on the platform. Please read the
            Terms carefully, as they form the entire agreement between you and Rework</p>
        <p><strong>Usage of Services</strong></p>
        <ol>
            <li>
                Posting content on the Platform:
                <ol>
                    <li>you shall be required to log on using your registered mobile number/ username/email address and
                        password.</li>
                    <li>you may upload/post: (i) content that either was created by you entirely or legally belongs to
                        you, or you have a legally valid license which permits you to upload/post the content; or (ii)
                        your reviews, comments, reactions, etc., to specific content on the Platform (the
                        content/information covered under (i) and (ii) above, the Content), and if in the event we
                        suffer any loss or damages on account of the Content you have uploaded/posted belonging to
                        another party or such Content violating or infringing the rights of any party, you shall be
                        liable to indemnify us for such loss and damages;</li>
                    <li>by uploading/posting any Content, and in consideration of you using the Services, you
                        automatically constitute you granting a royalty-free, perpetual, irrevocable, transferrable,
                        worldwide license to <b>Rework</b> to (a) use, reproduce, modify, adapt, publish, translate,
                        create derivative works from, distribute, perform, transmit and display such Content (in whole
                        or part) and/or to incorporate it in other works in any form, media, mode of delivery or
                        technology now known or later developed, (b) display online or offline your Content (or part
                        thereof, or any modification, adaptation, translation or derivative works thereof) online and
                        offline and permit others (including without limitation <b>Rework</b> co-brand partners) to do
                        the same, and (c) permit other users to access, reproduce, distribute, publicly display, prepare
                        derivative works of, and publicly perform your content via the Services, as may be permitted by
                        the functionality of those Services (e.g., for users to re-blog, re-post or download your
                        content). Further, the license you have granted to us as aforesaid shall not lapse
                        notwithstanding that we have not used, distributed, displayed, published, or adapted/modified
                        any of the Content for any duration.</li>
                    <li>
                        You shall, under no circumstances, upload any Content that:
                        <ol>
                            <li>belongs to another person and to which you do not have any right;</li>
                            <li>is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic,
                                paedophilic, libellous, invasive of anothers privacy, hateful, or racially, ethnically
                                objectionable, disparaging, relating or encouraging money laundering or gambling, or
                                otherwise unlawful in any manner;</li>
                            <li>harm minors in any way;</li>
                            <li>infringes any patent, trademark, copyright or other proprietary rights;</li>
                            <li>violates any law for the time being in force;</li>
                            <li>deceives or misleads the reader/viewer about the origin of any information, or contains
                                any information that is grossly offensive or menacing in nature;</li>
                            <li>impersonate another person;</li>
                            <li>contain software viruses or any other computer code, files or programmes that is
                                designed to interrupt, destroy or limit the functionality of any computer resource;</li>
                            <li>threatens the unity, integrity, defence security or sovereignty of India, friendly
                                relations with foreign states or public order or causes incitement to the commission of
                                any cognizable offence or prevents investigation of any offence or is insulting any
                                other nation.</li>
                        </ol>
                    </li>
                    <li>While <b>Rework</b> does not make any editorial determination about the Content to be displayed
                        and made accessible on the Platform, if: (i) we receive any notice or communication that any
                        Content or any part of the Content belongs to a party who has not consented to such content
                        being uploaded and accessible on the Platform or that it is violative of the rights of any
                        person who has not consented to such Content being uploaded and accessible on the Platform; or
                        (ii) it comes to our attention that any of the Content violates these Terms, we shall be
                        entitled to remove such content/block public access to such content either temporarily or
                        permanently, subject to the provisions of the Indian Copyright Act, 1957, and the Information
                        Technology Act, 2000, and as we deem fit or in compliance with the orders or directions of the
                        court (if applicable) and we shall have the right, at our discretion, to remove your account,
                        and in either case you shall have no right to make any claims against us for removing or
                        blocking such Content;</li>
                    <li>you understand that we act as a technological platform providing incidental, transient storage
                        of the content uploaded by you for the purpose of electronic transmission to and access by
                        members of the general public as understood under the Indian Copyright Act, 1957 and as an
                        intermediary as understood under the Indian Information Technology Act, 2000.</li>
                    <li>you shall be solely responsible for safeguarding your login details including username and
                        password and that we shall have no liability whatsoever.</li>
                </ol>
            </li>
            <br></br>
            <li>Authority. You agree that you are permitted to use the Services under applicable law. If you are
                accessing an account(s) on behalf of the account owner (e.g., as an administrator, consultant, analyst,
                etc.), the Terms apply to your activities on behalf of the account owner.</li>
                <br></br>
            <li>Age. If you are under the age of majority as per the laws of your country of residence (<strong> Minimum
                    Age </strong>) you may not register for an account. <b>Rework</b> does not include any age
                inappropriate. User data is processed by <b>Rework</b>, through verification and other internal checks,
                in a manner that both protects any user information as well as advances the best interests of children.
                Parents/adult guardians are required to monitor/regulate the content being viewed by their wards who
                have not attained the Minimum Age. Parental control tools available from third party vendors/service
                providers might be useful in this regard. It shall also be responsibility of parents/adult guardians to
                ensure that their wards do not view Content that is not appropriate for them.</li>
                <br></br>
            <li>
                Member Conduct. You agree not to use the Services to:
                <ol>
                    <li>obtain or attempt to obtain unauthorized access to the Services or to <b>Rework</b> servers,
                        systems, network, or data;</li>
                    <li>make available any Content that is harmful to children, threatening, abusive, harassing,
                        tortious, defamatory, vulgar, obscene, libelous, invasive of anothers privacy, hateful, or
                        racially, ethnically, or otherwise objectionable;</li>
                    <li>violate any applicable laws or regulations;</li>
                    <li>impersonate any person or entity; or forge or manipulate headers or identifiers to disguise the
                        origin of any content transmitted through the Service;</li>
                    <li>make available any Content that you do not have the right to make available or that infringes
                        any patent, trademark, trade secret, copyright or other proprietary rights of any person or
                        entity;</li>
                    <li>post Content containing advertisements or other commercial solicitations without our prior
                        written permission;</li>
                    <li>make available viruses or any other computer code, files, programs or Content designed to
                        interrupt, destroy or limit the functionality of the Services or affect other users; or</li>
                    <li>interfere with or disrupt the Services or servers, systems or networks connected to the Services
                        in any way.</li>
                </ol>
            </li>
            <br></br>
            <li>Use of Services. You must follow any guidelines or policies associated with the use of the Services. You
                must not misuse or interfere with the Services or try to access them using a method other than the
                interface and the instructions that we provide. You may use the Services only as permitted by law.
                Unless otherwise expressly stated, you may not access or reuse the Services, or any portion thereof, for
                any commercial purpose. Without our express written consent, you may not (a) use any automated means to
                access the Platform or collect any information from the Platform (including without limitation robots,
                spiders or scripts), or (b) frame the Platform (or any part thereof), place pop-up windows over the
                Platform (or any part thereof) pages, or otherwise affect the display of part or area of the Platform.
            </li>
            <br></br>
            <li>Anti-Corruption Laws. You agree to comply with all applicable anti-corruption laws including laws that
                prohibit unlawful payments to anyone for a corrupt purpose in relation to these Terms.</li><br></br>
            <li>Ownership and Reuse. Subject to these Terms, using the Services does not give you ownership of any
                intellectual or other property rights or interests in the Services or the Content you access. You must
                not use any branding or logos used in the Services unless <b>Rework</b> has given you separate explicit
                written permission. You may not remove, obscure, or alter any legal notices displayed on the Platform or
                in relation to any Content. Unless you have explicit written permission, you must not reproduce, modify,
                rent, lease, sell, trade, distribute, transmit, broadcast, publicly perform, create derivative works
                based on, or exploit for any commercial purposes, any portion of the Content or use of, or access to,
                the Services (including Content, advertisements, APIs, and software).</li><br></br>
            <li>Support. Unless otherwise expressly stated, <b>Rework</b> does not promise to provide you with any
                support for the Services. If <b>Rework</b> does provide you with support, it is at <b>Rework</b> sole
                discretion and does not mean that we will continue to provide you with support in the future.</li>
                <br></br>
            <li>Fees. <b>Rework</b>reserves the right to charge fees for use of or access to the Services (and any
                associated support), whether currently in existence or not, in <b>Rework</b> sole discretion. If
                <b>Rework</b> decides to charge fees <b>Rework</b> payment terms will apply and <b>Rework</b> will
                provide you with prior notice.</li><br></br>
            <li>Anti-Abuse Policy. <b>Rework</b> prohibits sending unsolicited emails or messages using the Services.
                You may not in connection with the Services engage in commercial activity on non-commercial properties
                or apps or high volume activity without <strong>Rework</strong> prior written consent. You may not
                engage in conduct or activity that is disruptive to the Services or the experience of other users.</li>
                <br></br>
        </ol>
      </div>  
    </div>
    <hr></hr>
  </>
  )
}
